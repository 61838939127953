.App {
  text-align: center;
}

.Column {
  margin: 20px auto;
  width: 90%;
  max-width: 320px;
}

